import React from "react";
const qrCode = require("../../assets/image/qrform.png");
export const QrCode = () => {
  return (
    <figure
    >
      <img src={qrCode} />
    </figure>
  );
};
