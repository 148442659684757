import React, { useState } from "react";
import { Logo, Modal, Searchbar, Map} from "./components";

import "./App.css";

function App() {
  const [cityId, setCityId] = useState(0);
  const [coords, setCoords] = useState({ lat: 48.914155, lon: 2.285369 });

  return (
    <div className="App">
      <Modal />
      <Logo />
      <Searchbar
        onLocationChanged={({ cityId, lat, lon }) => {
          console.debug("onLocationChanged", cityId, lat, lon);
          setCityId(cityId);
          setCoords({ lat, lon });
        }}
      ></Searchbar>
      <Map coords={coords} cityId={cityId} />
    </div>
  );
}

export default App;
