import { useState } from 'react'
import 'cross-fetch/polyfill'
import { apolloClient } from '../utils'
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'

export const useGraphQL = (): ApolloClient<NormalizedCacheObject> => {
    const [client] = useState(apolloClient)

    return client
}
