import React, { useState } from "react";
import "bulma/css/bulma.min.css";
import { gql, useLazyQuery } from "@apollo/client";
import { Form, Button } from "react-bulma-components";
import { VendorCount } from "../VendorCount";

type ISearchbar = {
  onLocationChanged: ({
    cityId,
    lat,
    lon,
  }: {
    cityId: number;
    lat: number;
    lon: number;
  }) => void;
};

export function Searchbar({ onLocationChanged }: ISearchbar) {
  const [postalCode, setPostalCode] = useState("92600");
  const [vendorPostalCode, setVendorPostalCode] = useState(postalCode);
  const [latLon, setLatLon] = useState({ lat: undefined, lon: undefined });

  const GET_CITY = gql`
    query GET_CITY($postalCode: String!) {
      postcodes(filter: { postcode: { _eq: $postalCode } }) {
        city_id {
          com
          nccenr
          latitude
          longitude
          boundaries
        }
      }
    }
  `;
  const [getCity, { loading, error, data }] = useLazyQuery(GET_CITY);

  const city = data?.postcodes?.[0]?.city_id;
  const latitude = city?.latitude;
  const longitude = city?.longitude;
  const newDataAvailable = latLon.lat !== latitude && latLon.lon !== longitude;
  if (newDataAvailable) {
    setLatLon({ lat: latitude, lon: longitude });
    onLocationChanged({ cityId: city?.com, lat: latitude, lon: longitude });
    setVendorPostalCode(postalCode);
  }

  console.debug({ city });
  console.debug({ latitude, longitude });

  const HandleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    console.debug(postalCode);
    await getCity({ variables: { postalCode } });
  };
  const onKeyDown = async (e: {
    key: string;
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    if (e.key === "Enter") {
      await getCity({ variables: { postalCode } });
    }
  };
  return (
    <>
    <Form.Field
      className="has-addons"
      style={{
        position: "fixed",
        top: 40,
        left: 100,
        right: 100,
        zIndex: 1 ,
        maxWidth: 800,
        margin: "auto",
      }}
    >
      <Form.Control className="is-expanded">
        <Form.Input
          placeholder="Saisir le code postal"
          type="text"
          onChange={(e) => setPostalCode(e.target.value)}
          size="large"
          autoFocus
          onKeyDown={onKeyDown}
        />
      </Form.Control>
      <Form.Control>
        <Button color="success" size="large" onClick={HandleSubmit}>
          Afficher les fournisseurs
        </Button>
      </Form.Control>
    </Form.Field>
    <VendorCount postal_code={vendorPostalCode}/>
    </>
  );
}
