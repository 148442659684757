import React from "react";
const eniaLogo = require("../../assets/image/eniaLogo.png");
export const Logo = () => {
  return (
    <figure
      className="image  is-96x96"
      style={{
        position: "absolute",
        top: 10,
        left: 10,
        zIndex: 1,
        maxWidth: 200,
        margin: "auto",
      }}
    >
      <a href="https://enia.green/">
        <img className="is-rounded" src={eniaLogo} />
      </a>
    </figure>
  );
};