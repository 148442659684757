import {
    ApolloClient,
    InMemoryCache,
    QueryOptions,
    ApolloQueryResult,
} from '@apollo/client'

// GraphQL client
export const apolloClient = new ApolloClient({
    uri: 'https://cms.atlas.dev.enia.green/graphql',
    cache: new InMemoryCache(),
    defaultOptions: {
        query: { errorPolicy: 'all' },
    },
})

export async function query(
    args: QueryOptions<Record<string, any>, any>,
): Promise<ApolloQueryResult<any>> {
    return apolloClient.query(args)
}
