import React, { useState } from "react";
import Modal from "react-modal";
import { Icon } from "react-bulma-components";
import { QrCode } from "../Pictures";
import "./Modal.css";

const ModalWrapper = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const setModalIsOpenToTrue = () => {
    setModalIsOpen(true);
  };
  const setModalIsOpenToFalse = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <button
        className="button is-success is-rounded"
        onClick={setModalIsOpenToTrue}
        style={{
          position: "fixed",
          bottom: 20,
          right: 10,
          zIndex: 1,
          maxWidth: 250,
          margin: "auto",
        }}
      >
        En savoir plus
      </button>
      <Modal
        isOpen={modalIsOpen}
        className="Modal"
        overlayClassName="Overlay"
        onRequestClose={setModalIsOpenToFalse}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <QrCode />
        <button
          className="button is-success is-inverted is-rounded"
          onClick={setModalIsOpenToFalse}
          style={{
            position: "absolute",
            top: 1,
            right: 1,
            zIndex: 99,
            maxWidth: 250,
            margin: "auto",
          }}
        >
          <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M376.6 427.5c11.31 13.58 9.484 33.75-4.094 45.06c-5.984 4.984-13.25 7.422-20.47 7.422c-9.172 0-18.27-3.922-24.59-11.52L192 305.1l-135.4 162.5c-6.328 7.594-15.42 11.52-24.59 11.52c-7.219 0-14.48-2.438-20.47-7.422c-13.58-11.31-15.41-31.48-4.094-45.06l142.9-171.5L7.422 84.5C-3.891 70.92-2.063 50.75 11.52 39.44c13.56-11.34 33.73-9.516 45.06 4.094L192 206l135.4-162.5c11.3-13.58 31.48-15.42 45.06-4.094c13.58 11.31 15.41 31.48 4.094 45.06l-142.9 171.5L376.6 427.5z" />
            </svg>
          </Icon>
        </button>
      </Modal>
    </div>
  );
};

export { ModalWrapper as Modal };
