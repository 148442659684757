import React, { useState, useEffect, useRef } from "react";
import { Feature, Map, View } from "ol";
import { FullScreen } from "ol/control";
import { GeoJSON, MVT } from "ol/format";
import { Circle, MultiPolygon } from "ol/geom";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  VectorTile as VectorTileLayer,
} from "ol/layer";
import { fromLonLat, useGeographic } from "ol/proj";
import { OSM, Vector, VectorTile } from "ol/source";
import { Fill, Stroke, Style, Text } from "ol/style";

import "ol/ol.css";

type IMap = {
  coords: { lon: number; lat: number };
  cityId: number;
};

function MapWrapper({ coords, cityId }: IMap) {
  useGeographic();
  const [map, setMap] = useState<Map>();
  const mapElement = useRef() as React.MutableRefObject<HTMLInputElement>;
  const origin = "https://tiles.atlas.dev.enia.green";

  const center = fromLonLat([coords.lon, coords.lat], "EPSG:4326");

  // Initial setup
  useEffect(() => {
    const initialMap = new Map({
      target: mapElement.current, // <- attach it to the actual element
      layers: [
        // Open Street Maps
        new TileLayer({
          source: new OSM(),
        }),

        // Classified facilities
        new VectorTileLayer({
          source: new VectorTile({
            url: `${origin}/atlas.classified_facilities/{z}/{x}/{y}.pbf`,
            tileSize: 512,
            format: new MVT(),
          }),
          renderMode: "vector",
          updateWhileAnimating: false,
          style: [
            new Style({
              text: new Text({
                text: "⬤",
                fill: new Fill({
                  color: "rgba(200, 100, 70, .5)",
                }),
              }),
            }),
          ],
        }),

        // RADIUS 50 KM
        new VectorLayer({
          properties: {
            name: "radius",
          },
          source: new Vector({
            // projection: "EPSG:4326",
            features: [new Feature(new Circle(center, 0.7))],
          }),
          style: [
            new Style({
              stroke: new Stroke({
                color: "rgba(60, 60, 60, 0.5)",
                width: 3,
              }),
              // fill: new Fill({
              //   color: "rgba(50, 200, 100, .15)",
              // }),
            }),
          ],
        }),

        // City coordinates
        new VectorLayer({
          source: new Vector({
            url: `https://geo.atlas.dev.enia.green/collections/atlas.cities/items/${cityId}.json`,
            format: new GeoJSON(),
          }),
        }),
      ],
      controls: [new FullScreen()],
      view: new View({
        enableRotation: false,
        center,
        zoom: 8,
        minZoom: 8,
        maxZoom: 10,
      }),
    });

    setMap(initialMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Whenever the coordinates change, update the location
  useEffect(() => {
    if (map && coords.lat && coords.lon) {
      const radius = map
        .getLayers()
        .getArray()
        .filter((layer) => {
          if (layer.getProperties() != undefined) {
            const properties = layer.getProperties();
            return (
              properties.name !== undefined && properties.name === "radius"
            );
          }
          return false;
        });
      map.removeLayer(radius[0]);
      map.getView().animate({
        zoom: 10,
        center,
      });
      map.addLayer(
        new VectorLayer({
          properties: {
            name: "radius",
          },
          source: new Vector({
            // projection: "EPSG:4326",
            features: [new Feature(new Circle(center, 0.7))],
          }),
          style: [
            new Style({
              stroke: new Stroke({
                color: "rgba(60, 60, 60, 0.5)",
                width: 3,
              }),
              // fill: new Fill({
              //   color: "rgba(50, 200, 100, .15)",
              // }),
            }),
          ],
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords, center]);

  return (
    <div
      ref={mapElement}
      className="map-container"
      style={{ height: "100vh", width: "100%" }}
    />
  );
}
export {MapWrapper as Map}