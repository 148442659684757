import React from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";

import "./VendorCount.css";

const Bounce = require("react-reveal/Bounce");
const Flip = require("react-reveal/Flip");

const GET_VENDORS_BY_NAF_AND_CP = gql`
  #   query getVendorsByNafAndCp($codesNaf: [String], $cp: String) {
  query getVendorsByNafAndCp($cp: String) {
    classified_facilities_aggregated(
      filter: {
        cd_postal: { _eq: $cp }
        # _and: [
        #   { cd_postal: { _eq: $cp } }
        #   {
        #     _or: [
        #       { code_naf: { code: { _in: $codesNaf } } }
        #       { code_naf: { code: { _starts_with: "01" } } }
        #       { code_naf: { code: { _starts_with: "03" } } }
        #       { code_naf: { code: { _starts_with: "10" } } }
        #       { code_naf: { code: { _starts_with: "11" } } }
        #       { code_naf: { code: { _starts_with: "46.3" } } }
        #       { code_naf: { code: { _starts_with: "47" } } }
        #       { code_naf: { code: { _starts_with: "56" } } }
        #     ]
        #   }
        # ]
      }
    ) {
      count {
        code: code_s3ic
      }
    }
  }
`;

export const VendorCount = (postal_code: any) => {
  const cp = postal_code.postal_code;
  console.log(cp);
  const data = useQuery(GET_VENDORS_BY_NAF_AND_CP, {
    variables: {
      codesNaf: [
        "02.30Z",
        "20.14Z",
        "20.15Z",
        "20.53Z",
        "46.17A",
        "46.17B",
        "46.21Z",
      ],
      cp,
    },
  });
  const count = data?.data?.classified_facilities_aggregated[0]?.count?.code;
  return (
    <Bounce spy={cp}>
      <p className="textCount">
        <Flip spy={count}>
          <span className="count">{(count || 0) + 3}</span>
        </Flip>
        fournisseurs potentiels autour de vous 📍
      </p>
    </Bounce>
  );
};
